<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        action: {
          hideEdit: true,
          onView: this.onView,
        },
        headers: [
          { text: 'Type', value: 'messageType', sortable: false },
          {
            text: 'Number',
            value: 'messageNumber',
            sortable: false,
            component: { name: 'dtView', props: { name: 'message', permission: 'message' } },
          },
          {
            text: 'Sender',
            value: 'from',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Receiver',
            value: 'to',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Started from',
            value: 'spaceId',
            getter: item => {
              if (item.spaceId) {
                return item.spaceId;
              }
              return item.to.name;
            },
            sortable: false,
            component: {
              name: 'dtView',
              props: {
                name: 'space',
                getter: item => {
                  if (item.spaceId) {
                    return this.$toView('space', item.spaceId._id);
                  }
                  return this.$toView('account', item.to._id);
                },
                permission: item => {
                  if (item.spaceId) {
                    return 'space';
                  }
                  return 'account';
                },
              },
            },
          },
        ],
      },
    };
  },
  async created() {
    this.options.filters = [
      {
        label: 'Message types',
        items: [
          { text: 'Direct message', value: 'Direct message' },
          { text: 'Booking request', value: 'Booking request' },
        ],
        key: 'messageTypes',
      },
      {
        label: 'TBD',
        items: [],
        key: 'tbd1',
        disabled: true,
      },
      {
        label: 'TBD',
        items: [],
        itemText: 'name',
        itemValue: '_id',
        key: 'tbd2',
        disabled: true,
      },
      {
        label: 'TBD',
        items: [],
        key: 'tbd3',
        disabled: true,
      },
    ];
  },
  methods: {
    onView(item) {
      if (item.bookingId) {
        this.$view('booking', item.bookingId);
      } else {
        this.$view('message', item._id);
      }
    },
  },
};
</script>
